import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { useState, useCallback, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { AuthContext } from './contexts/AuthContext'
import AppRoutes from './AppRoutes'
import { Layout } from './components/Layout'
import LoadingPage from './components/atoms/loading-page'
import Header from './components/organisms/header'
import Footer from './components/organisms/footer'
import CategoryApi from './api/category'
import NotificationApi from './api/notification'

const App = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [currentUser, setCurrentUser] = useState(null)
  const [currentModal, setCurrentModal] = useState({})
  const [showLoadingPage, setShowLoadingPage] = useState(false)
  const [listCategory, setListCategory] = useState([])
  const [countNotifications, setCountNotifications] = useState(null)

  const updateCurrentUser = useCallback(async (nguoiDung) => {
    if (!nguoiDung) {
      setCurrentUser(null)
      return
    }
  }, [])

  const { path: indexRoute } = _.find(AppRoutes, { index: true })

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === 2) {
        if (pathname === '/' || pathname === '/login') {
          navigate('/category-management')
        }
        return
      }
      if (pathname === '/' || pathname === '/login') {
        navigate(indexRoute)
      }
      return
    }

    const valueSessionStorage = sessionStorage.getItem('CurrentUser')
    if (valueSessionStorage) {
      setCurrentUser(JSON.parse(valueSessionStorage))
      return
    }
    const valueLocalStorage = localStorage.getItem('CurrentUser')
    if (valueLocalStorage) {
      sessionStorage.setItem('CurrentUser', valueLocalStorage)
      setCurrentUser(JSON.parse(valueLocalStorage))
      return
    }

    if (pathname === '/register') {
      navigate('/register')
      return
    }

    if (pathname === '/forgot-password') {
      navigate('/forgot-password')
      return
    }

    navigate('/login')
  }, [currentUser, navigate, pathname, indexRoute])

  useEffect(() => {
    if (currentUser) {
      const getListCategory = async () => {
        const result = await CategoryApi.getAllCategoryActive(currentUser.token)
        setListCategory(result)
      }
      getListCategory()
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser) {
      const getListNotifications = async () => {
        const result = await NotificationApi.getAllNotificationsByIdUser(currentUser.token)
        handleFilterNotifications(result)
      }
      getListNotifications()
    }
  }, [currentUser])

  const handleFilterNotifications = (data) => {
    const foundNotifications = data.filter((notification) => notification.markRead === false)
    let maxIndex = 0
    foundNotifications.map((item, index) => {
      if (index >= maxIndex) {
        maxIndex = index + 1
      }
    })
    setCountNotifications(maxIndex)
  }

  const authContextValue = useMemo(
    () => ({
      currentUser,
      updateCurrentUser,
      currentModal,
      setCurrentModal,
      setShowLoadingPage,
      listCategory,
      countNotifications,
      setCountNotifications,
    }),
    [
      currentUser,
      updateCurrentUser,
      currentModal,
      setCurrentModal,
      setShowLoadingPage,
      listCategory,
      countNotifications,
      setCountNotifications,
    ],
  )

  return (
    <AuthContext.Provider value={authContextValue}>
      {currentUser && <Header />}
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route
            return <Route key={index} {...rest} element={element} />
          })}
        </Routes>
      </Layout>
      {showLoadingPage && <LoadingPage />}
      {currentUser && <Footer />}
    </AuthContext.Provider>
  )
}

export default App
